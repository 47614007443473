import React, { useState } from 'react'
import { Button, FormColumn, FormGroup, FormRow, Heading } from '../ui'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField, Uploader, TextArea } from '../components'
import { usePost } from '../hooks/useXHR'

const ENDPOINT_URL = '/company.php'

interface CompanyProps {
  id: string
  heading: string
  article: string
  image: string
}

interface FormProps {
  value: CompanyProps
  endpoint: string
}

export const CompanyForm: React.FC<FormProps> = ({ value, endpoint }) => {
  const [posting, setPosting] = useState<boolean>(false)
  const { action: post } = usePost()

  const initialValues = {
    heading: value.heading || '',
    article: value.article || '',
    image: value.image || ''
  }

  const validationSchema = yup.object().shape({
    heading: yup
      .string()
      .max(20, 'Título da seção não pode ultrapassar 20 caracteres')
      .required('Esse campo é obrigatório')
      .trim(),
    article: yup.string().required('Esse campo é obrigatório'),
    image: yup.string().required('Esse campo é obrigatório')
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleSubmit
  } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: formValues => {
      setPosting(true)
      const { heading, article, image } = formValues
      post(ENDPOINT_URL, {
        heading: heading,
        article: article,
        image: image,
        id: value.id
      }).then(() => {
        window.location.reload(false)
      })
    }
  })

  const handleImageUpload = (file: string) => {
    setFieldValue('image', file)
    setFieldTouched('image', true, false)
    validateForm({ ...values, image: file })
  }

  const handleTextAreaChange = (text: string) => {
    setFieldValue('article', text)
    setFieldTouched('article', true, false)
    validateForm({ ...values, article: text })
  }

  const canPost = (): boolean => {
    const { heading, article, image } = values
    if (heading !== initialValues.heading) return true
    if (article !== initialValues.article) return true
    if (image !== initialValues.image) return true
    return false
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <FormColumn>
          <Heading>Empresa</Heading>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <TextField
            label="Título da seção"
            maxLength={20}
            {...getFieldProps('heading')}
            error={errors.heading && touched.heading ? errors.heading : ''}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FormGroup
            title="Texto da empresa"
            error={errors.article && touched.article ? errors.article : ''}
          >
            <TextArea onChange={handleTextAreaChange} value={values.article} />
          </FormGroup>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <FormGroup
            title="Imagem de fundo"
            error={errors.image && touched.image ? errors.image : ''}
          >
            <Uploader
              onUpload={handleImageUpload}
              {...getFieldProps('image')}
            />
          </FormGroup>
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn>
          <Button primary loading={posting} disabled={!isValid || !canPost()}>
            Salvar alterações
          </Button>
        </FormColumn>
      </FormRow>
    </form>
  )
}
