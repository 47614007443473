import styled, { css } from 'styled-components'

interface CardProps {
  src?: string
  contain?: boolean
}

export const CardWrapper = styled.div`
  position: relative;
  border-radius: 0.75rem;
  padding: 0.75rem;
  background: ${({ theme }) => theme.box};
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
  cursor: pointer;
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: white;
`

export const Image = styled.div`
  width: 100%;
  height: 15.625rem;
  background-color: white;
  background-image: url(${({ src }: CardProps) => src});
  background-repeat: no-repeat;
  background-position: center;
  ${({ contain }: CardProps) =>
    contain &&
    css`
      background-size: contain;
    `};
  border-radius: 0.25rem 0.25rem 0 0;
`
