import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext
} from 'react'
import { ThemeChanger } from '../../App'
import { STORAGE_KEY } from '../../constants'
import { Context } from '../../context/auth'
import {
  Wrapper,
  Widget,
  Box,
  Dropdown,
  Name,
  DropdownItem,
  Logout
} from './styles'

export const Profile: React.FC = () => {
  const { handleLogout } = useContext(Context)
  const { theme, handleTheme } = useContext(ThemeChanger)
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setHasFocus(false)
    }
  }, [])

  const storageValue = localStorage.getItem(STORAGE_KEY)

  if (!storageValue) return null

  const { firstname } = JSON.parse(storageValue)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside])

  const toggleMenu = () => {
    setHasFocus(current => !current)
  }

  return (
    <Wrapper ref={ref}>
      <Widget onClick={toggleMenu}>
        <Name>{firstname}</Name>
        <Box>{firstname.split('')[0]}</Box>
      </Widget>
      {hasFocus && (
        <Dropdown>
          <DropdownItem type="button" onClick={handleTheme}>
            Modo escuro: {theme.title === 'light' ? 'Desativado' : 'Ativo'}
          </DropdownItem>
          <Logout type="button" onClick={handleLogout}>
            Sair
          </Logout>
        </Dropdown>
      )}
    </Wrapper>
  )
}
