import React, { useContext, useState } from 'react'
import {
  Button,
  ContentContainer,
  ErrorLabel,
  FormColumn,
  FormRow,
  FormWrapper,
  Heading,
  SectionContainer,
  SignInPage,
  SignInPageBlock
} from '../../ui'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { PasswordField, TextField } from '../../components'

import { Context } from '../../context/auth'

import logo from './assets/main-logo--light.svg'

export const Login: React.FC = () => {
  const [posting, setPosting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { handleLogin: entraNessaPorra } = useContext(Context)

  const validationSchema = yup.object().shape({
    user: yup.string().required('Esse campo é obrigatório'),
    password: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Senha deve conter pelo menos oito caracteres')
  })

  const { errors, touched, getFieldProps, handleSubmit } = useFormik({
    initialValues: { user: '', password: '' },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: formValues => {
      setPosting(true)
      setErrorMessage('')
      const { user, password } = formValues
      entraNessaPorra({ user, password }).then(res => {
        setPosting(false)
        setErrorMessage(res)
      })
    }
  })

  return (
    <SignInPage>
      <SignInPageBlock>
        <img src={logo} alt="" />
      </SignInPageBlock>
      <SectionContainer>
        <ContentContainer alignCenter>
          <FormWrapper alignCenter width="20rem">
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormColumn>
                  <Heading>Entrar</Heading>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Usuário"
                    {...getFieldProps('user')}
                    error={errors.user && touched.user ? errors.user : ''}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <PasswordField
                    label="Senha"
                    {...getFieldProps('password')}
                    error={
                      errors.password && touched.password ? errors.password : ''
                    }
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Button primary loading={posting}>
                    Entrar
                  </Button>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  {!!errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
                </FormColumn>
              </FormRow>
            </form>
          </FormWrapper>
        </ContentContainer>
      </SectionContainer>
    </SignInPage>
  )
}
