import styled from 'styled-components'

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.font.fira};
  font-size: 1.125rem;
  font-weight: 400;
  color: ${({ theme }) => theme.general.text};
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.general.text};
  margin: 1.5rem 0;
  padding: 0.5rem 0;
`
