import styled, { css, keyframes } from 'styled-components'

const lanimacion = keyframes`
  0% {
    left: 0;
    width: 20%;
  }
  49.99% {
    left: 0;
    width: 100%;
  }
  50% {
    left: auto;
    right: 0;
    width: 100%;
  }
  100% {
    left: auto;
    right: 0;
    width: 20%;
  }
`

interface Props {
  isDragActive?: boolean
  isDragReject?: boolean
  uploadedImage?: string
}

export const Wrapper = styled.div`
  padding: 2.5rem 1.5rem;
  border: dashed 3px ${({ theme }) => theme.uploader.border};
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;

  ${({ isDragActive }: Props) =>
    isDragActive &&
    css`
      border-color: ${({ theme }) => theme.uploader.borderAccept};

      p {
        color: #95e58c;
      }
    `}
`

export const TextWrapper = styled.div`
  width: 100%;
  display: block;
  margin-left: 1.125rem;
`

export const Label = styled.p`
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.uploader.label};

  span {
    color: ${({ theme }) => theme.uploader.link};
  }

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

export const Tips = styled.p`
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  color: ${({ theme }) => theme.uploader.label};
`

export const ImageContainer = styled.div`
  display: flex;
  width: auto;
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
  border: solid 0.125rem ${({ theme }) => theme.uploader.border};

  > img {
    width: 100%;
  }
`

export const IconButton = styled.button`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #000000;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`

export const ProgressBar = styled.div`
  margin-top: 1rem;
  position: relative;
  width: 100%;
  height: 0.25rem;
  border-radius: 0.125rem;
  background-color: #e4effc;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0%;
    height: 0.25rem;
    border-radius: 0.125rem;
    left: 0;
    right: auto;
    top: 0;
    background-color: #169ec9;

    animation: ${lanimacion} 1s infinite alternate ease-in-out;
  }
`
