export const dark = {
  title: 'dark',
  font: {
    fira: '"Fira Sans", sans-serif',
    nunito: 'Nunito, sans-serif'
  },
  general: {
    background: '#1e2737',
    text: '#dce3e6',
    error: '#b72a2a',
    overlay: 'rgba(0, 0, 0, 0.65)'
  },
  modal: {
    background: '#1e2737',
    shadow: 'rgba(0, 0, 0, 0.16)'
  },
  sidebar: {
    background: '#131824',
    border: 'linear-gradient(to bottom, #353e56, #243b77)',
    color: '#768aaa',
    activeColor: '#d4dff1',
    activebackground: '#0e1014'
  },
  input: {
    border: 'linear-gradient(to bottom, #353e56, #243b77)',
    background: '#131824',
    label: '#cadee9',
    value: '#e0e2e3',
    placeholder: '#40494e',
    errorBorder: 'linear-gradient(to bottom, #d40789, #c71414)',
    errorMessage: '#b72a2a'
  },
  uploader: {
    border: '#6b8ee6',
    label: '#cadee9',
    link: '#5fd9ff',
    borderAccept: '#266d09'
  },
  button: {
    primary: {
      background: 'linear-gradient(to bottom right, #1193bc, #5fd9ff)',
      label: '#ffffff',
      hover: 'linear-gradient(to bottom right, #41a1bf, #a6e9fe)',
      active: 'linear-gradient(to bottom right, #007498, #16aad8)'
    },
    secondary: {
      background: 'transparent',
      label: '#ffffff',
      hover: '#2d3538',
      active: '#3e4c51'
    },
    disabled: {
      background: '#525252',
      label: '#909090',
      hover: '#474747'
    }
  },
  box: 'linear-gradient(to bottom, #2f3e57, #1e2d46)'
}

export const light = {
  title: 'light',
  font: {
    fira: '"Fira Sans", sans-serif',
    nunito: 'Nunito, sans-serif'
  },
  general: {
    background: '#f9f9ff',
    text: '#485156',
    error: '#c71414',
    overlay: 'rgba(255, 255, 255, 0.8)'
  },
  modal: {
    background: '#f9f9ff',
    shadow: 'rgba(86, 106, 142, 0.27)'
  },
  sidebar: {
    background: '#cdd3df',
    border: 'linear-gradient(to bottom, #c7cfe3, #7a859f)',
    color: '#4f6588',
    activeColor: '#101a2c',
    activebackground: '#a9b2c5'
  },
  input: {
    border: 'linear-gradient(to bottom, #98b3f8, #6b8ee6)',
    background: '#ffffff',
    label: '#516d7d',
    value: '#40494e',
    placeholder: '#adb7bc',
    errorBorder: 'linear-gradient(to bottom, #ff4a8e, #c71414)',
    errorMessage: '#c71414'
  },
  uploader: {
    border: '#6b8ee6',
    label: '#8e8e8e',
    link: '#1b9cc4',
    borderAccept: '#4ac107'
  },
  button: {
    primary: {
      background: 'linear-gradient(to bottom right, #1193bc, #5fd9ff)',
      label: '#ffffff',
      hover: 'linear-gradient(to bottom right, #41a1bf, #a6e9fe)',
      active: 'linear-gradient(to bottom right, #007498, #16aad8)'
    },
    secondary: {
      background: 'transparent',
      label: '#282828',
      hover: '#eceff5',
      active: '#dce3ea'
    },
    disabled: {
      background: '#bfbfbf',
      label: '#909090',
      hover: '#d9d9d9'
    }
  },
  box: 'linear-gradient(to bottom, #f2f2fc, #d1e6ef)'
}
