import { FormikErrors } from 'formik'
import React from 'react'
import {
  Wrapper,
  Label,
  LabelContainer,
  LengthContainer,
  Input,
  ErrorLabel,
  InputWrapper
} from './styles'

interface TextFieldProps {
  label: string
  maxLength?: number
  value: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  maxLength = 0,
  value = '',
  error,
  ...rest
}) => {
  return (
    <Wrapper>
      <LabelContainer>
        <Label>{label}</Label>
        {!!maxLength && (
          <LengthContainer>{maxLength - value?.length}</LengthContainer>
        )}
      </LabelContainer>
      <InputWrapper hasError={!!error}>
        <Input
          type="text"
          maxLength={maxLength || 524288}
          value={value}
          {...rest}
        />
      </InputWrapper>
      {!!error && <ErrorLabel>{error}</ErrorLabel>}
    </Wrapper>
  )
}
