import styled, { css, keyframes } from 'styled-components'

const etcetera = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

interface ButtonProps {
  primary?: boolean
  loading?: boolean
  danger?: boolean
}

export const StyledButton = styled.button`
  height: 3rem;
  border-radius: 0.25rem;
  padding: 0 2rem;
  font-family: ${({ theme }) => theme.font.nunito};
  font-weight: 600;

  font-size: 1rem;
  border: none;

  background: ${({ theme }) => theme.button.secondary.background};
  color: ${({ theme }) => theme.button.secondary.label};

  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.button.secondary.hover};
  }

  &:active {
    background: ${({ theme }) => theme.button.secondary.active};
  }

  ${({ loading }: ButtonProps) =>
    loading
      ? css`
          pointer-events: none;
        `
      : null}

  ${({ danger }: ButtonProps) =>
    danger &&
    css`
      background: ${({ theme }) => theme.button.secondary.background};
      color: ${({ theme }) => theme.general.error};

      &:hover {
        background: ${({ theme }) => theme.button.secondary.hover};
      }

      &:active {
        background: ${({ theme }) => theme.button.secondary.active};
      }
    `}

  ${({ primary }: ButtonProps) =>
    primary &&
    css`
      background: ${({ theme }) => theme.button.primary.background};
      color: ${({ theme }) => theme.button.primary.label};

      &:hover {
        background: ${({ theme }) => theme.button.primary.hover};
      }

      &:active {
        background: ${({ theme }) => theme.button.primary.active};
      }
    `}

  &:disabled {
    background: ${({ theme }) => theme.button.disabled.background};
    color: ${({ theme }) => theme.button.disabled.label};

    &:hover {
      background: ${({ theme }) => theme.button.disabled.hover};
    }

    &:active {
      background: ${({ theme }) => theme.button.disabled.hover};
    }
  }
`

export const LoadingState = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5px;
  width: 25px;

  div {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: white;

    &:nth-child(1) {
      animation: ${etcetera} 0.4s linear infinite 0s alternate;
    }
    &:nth-child(2) {
      animation: ${etcetera} 0.4s linear infinite 0.4s alternate;
    }
    &:nth-child(3) {
      animation: ${etcetera} 0.4s linear infinite 0.8s alternate;
    }
  }
`
