import { useState, useEffect } from 'react'
import { STORAGE_KEY } from '../constants'
import { light, dark } from '../styles/theme'

function usePersistedState(initialState: any) {
  const [state, setState] = useState(() => {
    const storageValue = localStorage.getItem(STORAGE_KEY)
    if (storageValue) {
      const { darkMode } = JSON.parse(storageValue)
      return darkMode ? dark : light
    } else {
      return initialState
    }
  })

  function updateLocalStorage() {
    const currentInfo = localStorage.getItem(STORAGE_KEY)
    if (currentInfo) {
      const parsedInfo = JSON.parse(currentInfo)
      const newInfo = JSON.stringify({
        ...parsedInfo,
        dark_mode: state.title === 'dark' ? 1 : 0
      })
      localStorage.setItem(STORAGE_KEY, newInfo)
    }
  }

  useEffect(() => {
    updateLocalStorage()

    // eslint-disable-next-line
  }, [state])

  return [state, setState]
}

export default usePersistedState
