import React from 'react'
import {
  Card,
  ImageContainer,
  SubHeading,
  TextContainer,
  Title
} from './styles'

interface ServiceCardProps {
  image?: string
  heading?: string
  subHeading?: string
  onClick?: () => void
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  image,
  heading,
  subHeading,
  onClick
}) => {
  return (
    <Card onClick={onClick}>
      <ImageContainer image={image} />
      <TextContainer>
        <Title>{heading}</Title>
        <SubHeading>{subHeading}</SubHeading>
      </TextContainer>
    </Card>
  )
}
