import React, { FunctionComponent, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Context } from './context/auth'

import {
  Brands,
  Clients,
  Company,
  Contact,
  Home,
  Login,
  ServicesPage
} from './pages'
import { LoadingScreen } from './ui'

interface CustomRouteProps {
  isPrivate?: boolean
  exact?: boolean
  path?: string
  component?: FunctionComponent
}

const CustomRoute: React.FC<CustomRouteProps> = ({ isPrivate, ...rest }) => {
  const { loading, authenticated } = useContext(Context)

  if (loading) {
    return <LoadingScreen />
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />
  }

  return <Route {...rest} />
}

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/inicio" />} />
      <CustomRoute exact path="/login" component={Login} />
      <CustomRoute isPrivate exact path="/inicio" component={Home} />
      <CustomRoute isPrivate exact path="/marcas" component={Brands} />
      <CustomRoute isPrivate exact path="/servicos" component={ServicesPage} />
      <CustomRoute isPrivate exact path="/clientes" component={Clients} />
      <CustomRoute isPrivate exact path="/empresa" component={Company} />
      <CustomRoute isPrivate exact path="/contato" component={Contact} />
    </Switch>
  )
}
