import React from 'react'
import { Container, Row, Column } from './styles'

interface FormProps {
  alignCenter?: boolean
  columns?: string
  width?: string
  children?: React.ReactNode | React.ReactNode[]
}

export const FormWrapper: React.FC<FormProps> = ({
  children,
  alignCenter,
  width
}) => {
  return (
    <Container width={width} center={alignCenter}>
      {children}
    </Container>
  )
}

export const FormRow: React.FC<FormProps> = ({ columns, children }) => {
  return <Row columns={columns}>{children}</Row>
}

export const FormColumn: React.FC<FormProps> = ({ children }) => {
  return <Column>{children}</Column>
}
