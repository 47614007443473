import React from 'react'
import { Header, Wrapper } from './styles'

interface HeaderProps {
  title: string
  children?: JSX.Element
}

export const ListHeader: React.FC<HeaderProps> = ({ title, children }) => {
  return (
    <Wrapper>
      <Header>{title}</Header>
      {children}
    </Wrapper>
  )
}
