/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  Button,
  Card,
  ContentContainer,
  FormColumn,
  FormRow,
  FormWrapper,
  Grid,
  Heading,
  ListHeader,
  LoadingScreen,
  PageContainer,
  SectionContainer,
  Sidebar
} from '../../ui'

import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  TextField,
  ClientModal,
  Profile,
  AddClientModal
} from '../../components'
import { useFetch, usePost } from '../../hooks/useXHR'

const ENDPOINT_URL = '/clients.php'

interface ClientProps {
  id_clients: string
  name: string
  logo: string
  url: string
  activate: string
  update: string
}

export const Clients: React.FC = () => {
  const [posting, setPosting] = useState<boolean>(false)
  const [editClientIsOpen, setEditClientIsOpen] = useState<boolean>(false)
  const [addClientIsOpen, setAddClientIsOpen] = useState<boolean>(false)
  const [activeClient, setActiveClient] = useState<number>(0)
  const { data, loading: fetchingData } = useFetch(ENDPOINT_URL)
  const { action: post } = usePost()

  const initialValues = {
    title: data?.clients?.title || ''
  }

  const handleEditClient = (index: number) => {
    setActiveClient(index)
    setEditClientIsOpen(true)
  }

  const handleCloseEditClientModal = () => {
    setEditClientIsOpen(false)
  }

  const handleCloseAddClientModal = () => {
    setAddClientIsOpen(false)
  }

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .max(20, 'Título da seção não pode ultrapassar 20 caracteres')
      .required('Esse campo é obrigatório')
      .trim()
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    handleSubmit
  } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: formValues => {
      setPosting(true)
      const { title } = formValues
      post(ENDPOINT_URL, {
        title,
        id_text: data?.clients?.id_text
      }).then(() => {
        window.location.reload(false)
      })
    }
  })

  const canPost = (): boolean => {
    const { title } = values
    if (title !== initialValues.title) return true
    return false
  }

  if (fetchingData) return <LoadingScreen />

  return (
    <PageContainer>
      <Sidebar />
      <SectionContainer>
        <Profile />
        <ContentContainer>
          <FormWrapper>
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormColumn>
                  <Heading>Clientes</Heading>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Título da seção"
                    maxLength={20}
                    {...getFieldProps('title')}
                    error={errors.title && touched.title ? errors.title : ''}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Button
                    primary
                    loading={posting}
                    disabled={!isValid || !canPost()}
                  >
                    Salvar alterações
                  </Button>
                </FormColumn>
              </FormRow>
            </form>
          </FormWrapper>
          <ListHeader title="Lista de clientes">
            <Button
              type="button"
              primary
              onClick={() => setAddClientIsOpen(true)}
            >
              Adicionar cliente
            </Button>
          </ListHeader>
          <Grid columns="1fr 1fr 1fr 1fr" rows="1fr 1fr">
            {data?.clients?.list?.map((client: ClientProps, index: number) => (
              <Card
                key={client.id_clients}
                image={client.logo}
                contain
                onClick={() => handleEditClient(index)}
              />
            ))}
          </Grid>
          {editClientIsOpen && (
            <ClientModal
              value={data?.clients?.list[activeClient]}
              onClose={handleCloseEditClientModal}
              onChange={() => window.location.reload(false)}
            />
          )}
          {addClientIsOpen && (
            <AddClientModal
              onClose={handleCloseAddClientModal}
              onCreate={() => window.location.reload(false)}
            />
          )}
        </ContentContainer>
      </SectionContainer>
    </PageContainer>
  )
}
