import React from 'react'
import { LoadingState, StyledButton } from './styles'

interface ButtonProps {
  children: JSX.Element | string
  primary?: boolean
  danger?: boolean
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const Button: React.FC<ButtonProps> = ({
  children,
  primary,
  danger,
  onClick,
  disabled = false,
  loading,
  type = 'submit'
}) => {
  return (
    <StyledButton
      primary={primary}
      danger={danger}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      type={type}
    >
      {loading ? (
        <LoadingState>
          <div />
          <div />
          <div />
        </LoadingState>
      ) : (
        children
      )}
    </StyledButton>
  )
}
