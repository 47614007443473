import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 12.5rem;
  display: flex;
  flex-direction: column;
`
export const Widget = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 2.625rem;
  background: ${({ theme }) => theme.sidebar.background};
  border-radius: 0.25rem;
  cursor: pointer;
`

export const Box = styled.div`
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.button.primary.background};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;

  font-family: ${({ theme }) => theme.font.fira};
  font-size: 1.125;
  font-weight: 400;
  color: ${({ theme }) => theme.button.primary.label};
`

export const Name = styled.p`
  font-family: ${({ theme }) => theme.font.fira};
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.sidebar.color};
  padding: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Dropdown = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.sidebar.background};
  overflow: hidden;
`

export const DropdownItem = styled.button`
  height: 2.625rem;
  width: 100%;
  background: transparent;
  border: none;
  text-align: left;
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.sidebar.color};
  padding-left: 1.25rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.sidebar.activebackground};
    color: ${({ theme }) => theme.sidebar.activeColor};
  }
`

export const Logout = styled.button`
  height: 2.625rem;
  width: 100%;
  background: transparent;
  border: none;
  text-align: left;
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.general.error};
  padding-left: 1.25rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.sidebar.activebackground};
  }
`
