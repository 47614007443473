/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  Button,
  Card,
  ContentContainer,
  FormColumn,
  FormRow,
  FormWrapper,
  Grid,
  Heading,
  ListHeader,
  LoadingScreen,
  PageContainer,
  SectionContainer,
  Sidebar
} from '../../ui'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField, BrandModal, Profile, AddBrandModal } from '../../components'
import { useFetch, usePost } from '../../hooks/useXHR'

const ENDPOINT_URL = '/brands.php'

interface BrandProps {
  id_brands: string
  name: string
  logo: string
  url: string
  activate: string
  update: string
}

export const Brands: React.FC = () => {
  const [posting, setPosting] = useState<boolean>(false)
  const [editBrandIsOpen, setEditBrandIsOpen] = useState<boolean>(false)
  const [addBrandIsOpen, setAddBrandIsOpen] = useState<boolean>(false)
  const [activeBrand, setActiveBrand] = useState<number>(0)
  const { data, loading: fetchingData } = useFetch(ENDPOINT_URL)
  const { action: post } = usePost()

  const initialValues = {
    title: data?.brands?.title || ''
  }

  const handleEditBrand = (index: number) => {
    setActiveBrand(index)
    setEditBrandIsOpen(true)
  }

  const handleCloseEditBrandModal = () => {
    setEditBrandIsOpen(false)
  }

  const handleCloseAddBrandModal = () => {
    setAddBrandIsOpen(false)
  }

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .max(20, 'Título da seção não pode ultrapassar 20 caracteres')
      .required('Esse campo é obrigatório')
      .trim()
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    handleSubmit
  } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: formValues => {
      setPosting(true)
      const { title } = formValues
      post(ENDPOINT_URL, {
        title,
        id_text: data?.brands?.id_text
      }).then(() => {
        setPosting(false)
        window.location.reload(false)
      })
    }
  })

  const canPost = (): boolean => {
    const { title } = values
    if (title !== initialValues.title) return true
    return false
  }

  if (fetchingData) return <LoadingScreen />

  return (
    <PageContainer>
      <Sidebar />
      <SectionContainer>
        <Profile />
        <ContentContainer>
          <FormWrapper>
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormColumn>
                  <Heading>Marcas</Heading>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Título da seção"
                    maxLength={20}
                    {...getFieldProps('title')}
                    error={errors.title && touched.title ? errors.title : ''}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Button
                    primary
                    loading={posting}
                    disabled={!isValid || !canPost()}
                  >
                    Salvar alterações
                  </Button>
                </FormColumn>
              </FormRow>
            </form>
          </FormWrapper>
          <ListHeader title="Lista de marcas">
            <Button
              type="button"
              primary
              onClick={() => setAddBrandIsOpen(true)}
            >
              Adicionar marca
            </Button>
          </ListHeader>
          <Grid columns="1fr 1fr 1fr 1fr" rows="1fr 1fr">
            {data?.brands?.list?.map((brand: BrandProps, index: number) => (
              <Card
                key={brand.id_brands}
                image={brand.logo}
                onClick={() => handleEditBrand(index)}
              />
            ))}
          </Grid>
          {editBrandIsOpen && (
            <BrandModal
              value={data?.brands?.list[activeBrand]}
              onClose={handleCloseEditBrandModal}
              onChange={() => window.location.reload(false)}
            />
          )}
          {addBrandIsOpen && (
            <AddBrandModal
              onClose={handleCloseAddBrandModal}
              onCreate={() => window.location.reload(false)}
            />
          )}
        </ContentContainer>
      </SectionContainer>
    </PageContainer>
  )
}
