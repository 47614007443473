import styled, { css } from 'styled-components'

interface TextFieldProps {
  hasError?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
`
export const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`
export const Label = styled.label`
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.input.label};
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3rem;
  padding: 1px;
  border-radius: 5px;
  ${({ hasError }: TextFieldProps) =>
    hasError
      ? css`
          background: ${({ theme }) => theme.input.errorBorder};
        `
      : css`
          background: ${({ theme }) => theme.input.border};
        `}
`

export const ShowHideButton = styled.button`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;

  img {
    max-width: 2rem;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  padding: 0 1.25rem;
  background-color: ${({ theme }) => theme.input.background};
  border: none;
  color: ${({ theme }) => theme.input.value};
`

export const ErrorLabel = styled.p`
  margin-top: 0.25rem;
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.general.error};
`
