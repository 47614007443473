import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html, body {
    min-height: 100vh;
    font-family: ${({ theme }) => theme.font.nunito}
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    border: none;
    max-width: 100%;
    height: auto;
  }
  
  input {
    font-family: inherit;
  }
`
