import React, { useCallback, useEffect, useState, MouseEvent } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'

import {
  IconButton,
  ImageContainer,
  Label,
  ProgressBar,
  TextWrapper,
  Tips,
  Wrapper
} from './styles'
import { ImageIcon } from './assets/ImageIcon'
import deleteIcon from './assets/delete.svg'

interface UploaderProps {
  tips?: string
  multiple?: boolean
  accept?: string[]
  value?: any
  onUpload: (file: string) => void
}

export const Uploader: React.FC<UploaderProps> = ({
  tips = '',
  accept,
  value = null,
  onUpload
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [image, setImage] = useState(value)

  useEffect(() => {
    onUpload(image)
  }, [image])

  useEffect(() => {
    setImage(value)
  }, [value])

  const removeFile = (e: MouseEvent) => {
    e.stopPropagation()
    setImage('')
  }

  const processFile = (uploadedFile: File) => {
    const fileReader = new FileReader()

    fileReader.onloadend = function () {
      setImage(fileReader.result)
      setIsUploading(false)
    }

    fileReader.readAsDataURL(uploadedFile)
  }

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length) {
        alert('arquivo nao suportado')
      }
      if (acceptedFiles.length) {
        setIsUploading(true)
        processFile(acceptedFiles[0])
      }
    },
    []
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: accept?.join(',')
  })

  if (image) {
    return (
      <ImageContainer>
        <img src={image} alt="" />
        <IconButton type="button" onClick={removeFile}>
          <img src={deleteIcon} alt="" />
        </IconButton>
      </ImageContainer>
    )
  }

  return (
    <Wrapper
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <input {...getInputProps()} />
      {!isUploading ? (
        <>
          <ImageIcon />
          <TextWrapper>
            <Label>
              <span>Clique aqui</span> ou arraste uma imagem para fazer upload
            </Label>
            {!!tips && <Tips>{tips}</Tips>}
          </TextWrapper>
        </>
      ) : (
        <>
          <ImageIcon />
          <TextWrapper>
            <Tips>Carregando imagem...</Tips>
            <ProgressBar />
          </TextWrapper>
        </>
      )}
    </Wrapper>
  )
}
