/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  Button,
  ContentContainer,
  FormColumn,
  FormRow,
  FormWrapper,
  Grid,
  Heading,
  ListHeader,
  LoadingScreen,
  PageContainer,
  SectionContainer,
  ServiceCard,
  Sidebar
} from '../../ui'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { TextField, ServicePage, Profile } from '../../components'
import { useFetch, usePost } from '../../hooks/useXHR'

const ENDPOINT_URL = '/services.php'

interface ServiceInterface {
  id_services: string
  heading: string
  subheading: string
  image: string
  topics: string
}

export const ServicesPage: React.FC = () => {
  const [posting, setPosting] = useState<boolean>(false)
  const [editServiceIsOpen, setEditServiceIsOpen] = useState<boolean>(false)
  const [activeService, setActiveService] = useState<number>(0)
  const { data, loading: fetchingData } = useFetch(ENDPOINT_URL)
  const { action: post } = usePost()

  const initialValues = {
    title: data?.services?.title || '',
    text: data?.services?.text || ''
  }

  const editService = (index: number) => {
    setActiveService(index)
    setEditServiceIsOpen(true)
  }

  const handleCloseEditServiceModal = () => {
    setEditServiceIsOpen(false)
  }

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .max(20, 'Título da seção não pode ultrapassar 20 caracteres')
      .required('Esse campo é obrigatório')
      .trim(),
    text: yup.string().required('Esse campo é obrigatório')
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    handleSubmit
  } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: formValues => {
      setPosting(true)
      const { title, text } = formValues
      post(ENDPOINT_URL, {
        title,
        text,
        id: data?.company[0]?.id,
        id_text_title: data?.services?.id_text_title,
        id_text_text: data?.services?.id_text_text
      }).then(() => {
        window.location.reload(false)
      })
    }
  })

  const canPost = (): boolean => {
    const { title, text } = values
    if (title !== initialValues.title) return true
    if (text !== initialValues.text) return true
    return false
  }

  if (fetchingData) return <LoadingScreen />

  return (
    <PageContainer>
      <Sidebar />
      <SectionContainer>
        <Profile />
        <ContentContainer>
          <FormWrapper>
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormColumn>
                  <Heading>Serviços</Heading>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Título da seção"
                    maxLength={20}
                    {...getFieldProps('title')}
                    error={errors.title && touched.title ? errors.title : ''}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Texto da seção serviços"
                    {...getFieldProps('text')}
                    error={errors.text && touched.text ? errors.text : ''}
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Button
                    primary
                    loading={posting}
                    disabled={!isValid || !canPost()}
                  >
                    Salvar alterações
                  </Button>
                </FormColumn>
              </FormRow>
            </form>
          </FormWrapper>
          <ListHeader title="Lista de serviços" />
          <Grid columns="1fr 1fr 1fr">
            {data?.services?.list?.map(
              (service: ServiceInterface, index: number) => (
                <ServiceCard
                  key={service.id_services}
                  image={service.image}
                  heading={service.heading}
                  subHeading={service.subheading}
                  onClick={() => editService(index)}
                />
              )
            )}
          </Grid>
          {editServiceIsOpen && (
            <ServicePage
              value={data?.services?.list[activeService]}
              onClose={handleCloseEditServiceModal}
              onChange={() => window.location.reload(false)}
            />
          )}
        </ContentContainer>
      </SectionContainer>
    </PageContainer>
  )
}
