import React, { useState, useRef } from 'react'
import { FormikErrors } from 'formik'
import {
  Wrapper,
  Label,
  LabelContainer,
  Input,
  ErrorLabel,
  InputWrapper,
  ShowHideButton
} from './styles'

import removeRedEye from './assets/remove_red_eye.svg'
import visibilityOff from './assets/visibility_off.svg'

interface PasswordFieldProps {
  label: string
  value: string
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  label,
  value = '',
  error,
  ...rest
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const ref = useRef<HTMLInputElement>(null)

  const handleFieldVisibility = () => {
    setVisible(current => !current)
    ref?.current?.focus()
  }

  return (
    <Wrapper>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <InputWrapper hasError={!!error}>
        <Input
          ref={ref}
          type={visible ? 'text' : 'password'}
          value={value}
          {...rest}
        />
        <ShowHideButton type="button" onClick={handleFieldVisibility}>
          {visible ? (
            <img src={visibilityOff} alt="Exibir senha" />
          ) : (
            <img src={removeRedEye} alt="Esconder senha" />
          )}
        </ShowHideButton>
      </InputWrapper>
      {!!error && <ErrorLabel>{error}</ErrorLabel>}
    </Wrapper>
  )
}
