/* eslint-disable camelcase */
import React from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { usePost } from '../../hooks/useXHR'
import {
  Button,
  ButtonWrapper,
  FormColumn,
  FormGroup,
  FormRow,
  Modal
} from '../../ui'
import { TextField, Uploader } from '..'

const ENDPOINT_CLIENTS = '/clients.php'

interface AddProps {
  onCreate: () => void
  onClose: () => void
}

export const AddClientModal: React.FC<AddProps> = ({ onCreate, onClose }) => {
  const { action: post } = usePost()

  const validationSchema = yup.object().shape({
    name: yup.string().required('Esse campo é obrigatório').trim(),
    logo: yup.string().required('Esse campo é obrigatório'),
    url: yup.string().required('Esse campo é obrigatório')
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleSubmit
  } = useFormik({
    initialValues: { name: '', logo: '', url: '' },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: formValues => {
      const { name, logo, url } = formValues
      post(
        ENDPOINT_CLIENTS,
        {
          name,
          logo,
          url
        },
        true
      ).then(() => {
        onCreate()
      })
    }
  })

  const handleImageUpload = (file: string) => {
    setFieldValue('logo', file)
    setFieldTouched('logo', true, false)
    validateForm({ ...values, logo: file })
  }

  return (
    <Modal title="Adicionar cliente" onClose={onClose} showCloseButton>
      <form onSubmit={handleSubmit}>
        <FormRow>
          <FormColumn>
            <TextField
              label="Nome do cliente"
              {...getFieldProps('name')}
              error={errors.name && touched.name ? errors.name : ''}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <TextField
              label="Link para o site do cliente"
              {...getFieldProps('url')}
              error={errors.url && touched.url ? errors.url : ''}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup
              title="Logotipo do cliente"
              error={errors.logo && touched.logo ? errors.logo : ''}
            >
              <Uploader
                onUpload={handleImageUpload}
                accept={['image/png', 'image/jpg', 'image/jpeg']}
                {...getFieldProps('logo')}
              />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <ButtonWrapper>
              <Button type="submit" primary disabled={!isValid}>
                Salvar
              </Button>
            </ButtonWrapper>
          </FormColumn>
        </FormRow>
      </form>
    </Modal>
  )
}
