import React from 'react'
import {
  ContentContainer,
  FormWrapper,
  LoadingScreen,
  PageContainer,
  SectionContainer,
  Sidebar
} from '../../ui'

import { Profile } from '../../components'
import { useFetch } from '../../hooks/useXHR'

import { CompanyForm } from '../../forms/CompanyForm'

const ENDPOINT_URL = '/company.php'

export const Company: React.FC = () => {
  const { data, loading: fetchingData } = useFetch(ENDPOINT_URL)

  if (fetchingData) return <LoadingScreen />

  const value = {
    id: data?.company[0]?.id,
    heading: data?.company[0]?.heading,
    article: data?.company[0]?.article,
    image: data?.company[0]?.image
  }

  return (
    <PageContainer>
      <Sidebar />
      <SectionContainer>
        <Profile />
        <ContentContainer>
          <FormWrapper alignCenter>
            <CompanyForm value={value} endpoint={ENDPOINT_URL} />
          </FormWrapper>
        </ContentContainer>
      </SectionContainer>
    </PageContainer>
  )
}
