import React from 'react'
import { StyledButton } from './styles'

import { Cross } from './assets/Icons'

interface ButtonProps {
  onClick?: () => void
}

export const ButtonIcon: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick} type="button">
      <Cross />
    </StyledButton>
  )
}
