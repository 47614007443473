import React from 'react'
import { ButtonIcon } from '../ButtonIcon/ButtonIcon'
import { Body, Box, Header, Overlay, Title } from './styles'

interface ModalProps {
  title?: string
  children: JSX.Element
  onClose?: () => void
  showCloseButton?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  title = '',
  children,
  onClose,
  showCloseButton
}) => {
  return (
    <>
      <Overlay onClick={onClose} />
      <Box>
        <Header>
          <Title>{title}</Title>
          {showCloseButton && <ButtonIcon onClick={onClose} />}
        </Header>
        <Body>{children}</Body>
      </Box>
    </>
  )
}
