import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const Label = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.input.label};
  margin-bottom: 0.75rem;
`

export const ErrorLabel = styled.p`
  margin-top: 0.25rem;
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.general.error};
`
