import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.input.border};
  border-radius: 5px;
  padding: 1px;

  .rdw-editor-wrapper {
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }

  .rdw-editor-toolbar {
    padding: 0.5rem;
    border-top: 0.125rem solid rgba(0, 0, 0, 0.05);
    margin-bottom: 0;
  }

  .rdw-inline-wrapper {
    margin-bottom: 0;
  }

  .rdw-editor-main {
    font-family: 'Nunito', sans-serif;
    color: #323239;
    padding: 0 1rem;
    background-color: white;
  }
  .rdw-option-wrapper {
    min-width: auto;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
    border: none;
  }
  .rdw-option-wrapper img {
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0.25;
  }
  .rdw-option-wrapper:hover {
    box-shadow: none;
    background-color: white;
  }
  .rdw-option-active {
    box-shadow: none;
  }
  .rdw-option-active img {
    opacity: 0.85;
  }
`
