import styled, { keyframes } from 'styled-components'

const formSideToSide = keyframes`
  0% {
    left: 0;
    width: 20%;
  }
  49.99% {
    left: 0;
    width: 100%;
  }
  50% {
    left: auto;
    right: 0;
    width: 100%;
  }
  100% {
    left: auto;
    right: 0;
    width: 20%;
  }
`
export const AnimationBar = styled.div`
  margin-top: 1rem;
  position: relative;
  width: 100%;
  max-width: 20rem;
  height: 0.25rem;
  border-radius: 0.125rem;
  background-color: ${({ theme }) => theme.button.secondary.hover};

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0%;
    height: 0.25rem;
    border-radius: 0.125rem;
    left: 0;
    right: auto;
    top: 0;
    background-color: ${({ theme }) => theme.uploader.border};

    animation: ${formSideToSide} 1.2s infinite alternate ease-in-out;
  }
`

export const Label = styled.h3`
  font-family: ${({ theme }) => theme.font.fira};
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  color: ${({ theme }) => theme.general.text};
  margin-bottom: 0.75rem;
  text-align: center;
`

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.general.background};
`
