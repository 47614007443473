import styled, { css } from 'styled-components'

interface IRow {
  columns?: string
  center?: boolean
  width?: string
}

export const Container = styled.div`
  width: ${({ width }: IRow) => width || '100%'};
  max-width: 40rem;
  min-width: 20rem;

  ${({ center }: IRow) =>
    center &&
    css`
      margin: 0 auto;
    `}
`

export const Row = styled.div`
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: grid;
  grid-template-columns: ${({ columns }: IRow) => columns || '1fr'};
  column-gap: 1.25rem;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

export const Column = styled.div`
  width: 100%;
  display: block;
`
