/* eslint-disable camelcase */
import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { usePost } from '../../hooks/useXHR'
import { Button, FormColumn, FormGroup, FormRow, Modal } from '../../ui'
import { TextField } from '../TextField/TextField'
import { TextArea, Uploader } from '..'

const ENDPOINT_URL = '/services.php'

interface ServiceInfoProps {
  id_services: string
  heading: string
  subheading: string
  image: string
  topics: string
}

interface ServiceProps {
  value: ServiceInfoProps
  onClose: () => void
  onChange: () => void
}

export const ServicePage: React.FC<ServiceProps> = ({
  value,
  onClose,
  onChange
}) => {
  const [posting, setPosting] = useState<boolean>(false)
  const { action: post } = usePost()

  const validationSchema = yup.object().shape({
    heading: yup.string().required('Esse campo é obrigatório').trim(),
    subheading: yup.string().required('Esse campo é obrigatório'),
    topics: yup.string().required('Esse campo é obrigatório'),
    image: yup.string().required('Esse campo é obrigatório')
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleSubmit
  } = useFormik({
    initialValues: value,
    validationSchema,
    enableReinitialize: true,
    isInitialValid: validationSchema.isValidSync(value),
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: formValues => {
      setPosting(true)
      const { heading, subheading, image, topics } = formValues
      post(ENDPOINT_URL, {
        id_services: value.id_services,
        heading,
        subheading,
        image,
        topics
      }).then(() => {
        onChange()
      })
    }
  })

  const handleTextAreaChange = (text: string) => {
    setFieldValue('topics', text)
    setFieldTouched('topics', true, false)
    validateForm({ ...values, topics: text })
  }

  const handleImageUpload = (file: string) => {
    setFieldValue('image', file)
    setFieldTouched('image', true, false)
    validateForm({ ...values, image: file })
  }

  const canPost = (): boolean => {
    const { heading, subheading, topics, image } = values
    if (heading !== value.heading) return true
    if (subheading !== value.subheading) return true
    if (topics !== value.topics) return true
    if (image !== value.image) return true
    return false
  }

  return (
    <Modal title="Editar serviço" onClose={onClose} showCloseButton>
      <form onSubmit={handleSubmit}>
        <FormRow>
          <FormColumn>
            <TextField
              label="Título do serviço"
              maxLength={40}
              {...getFieldProps('heading')}
              error={errors.heading && touched.heading ? errors.heading : ''}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <TextField
              label="Subtítulo do serviço"
              {...getFieldProps('subheading')}
              error={
                errors.subheading && touched.subheading ? errors.subheading : ''
              }
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup
              title="Texto do serviço"
              error={errors.topics && touched.topics ? errors.topics : ''}
            >
              <TextArea onChange={handleTextAreaChange} value={values.topics} />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormGroup
              title="Imagem de fundo"
              error={errors.image && touched.image ? errors.image : ''}
            >
              <Uploader
                onUpload={handleImageUpload}
                accept={['image/png', 'image/jpg', 'image/jpeg']}
                {...getFieldProps('image')}
              />
            </FormGroup>
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <Button primary loading={posting} disabled={!isValid || !canPost()}>
              Salvar alterações
            </Button>
          </FormColumn>
        </FormRow>
      </form>
    </Modal>
  )
}
