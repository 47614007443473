import styled, { css } from 'styled-components'
import background from './assets/bg.png'

interface PageProps {
  alignCenter?: boolean
}

export const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 15rem 1fr;
`

export const SectionContainer = styled.section`
  display: grid;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.general.background};
  flex: 1;
  overflow-y: auto;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3.75rem 2rem;
  ${({ alignCenter }: PageProps) =>
    alignCenter &&
    css`
      justify-content: center;
    `}
`

export const SignInPage = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 3fr;
`

export const SignInPageBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${background});

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: relative;
    z-index: 3;
    max-width: 250px;
  }

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.22);
  }

  &::after {
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(to bottom, #172833, #0d4162);
    opacity: 0.5;
    z-index: 2;
  }
`

export const ForgotPasswordRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const ErrorLabel = styled.p`
  margin-top: 0.25rem;
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.general.error};
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5rem;
  }
`
