/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  Button,
  ContentContainer,
  FormColumn,
  FormGroup,
  FormRow,
  FormWrapper,
  Heading,
  LoadingScreen,
  PageContainer,
  SectionContainer,
  Sidebar
} from '../../ui'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { Profile, TextField, Uploader } from '../../components'
import { useFetch, usePost } from '../../hooks/useXHR'

const ENDPOINT_URL = '/landing.php'

export const Home: React.FC = () => {
  const [posting, setPosting] = useState<boolean>(false)
  const { data, loading: fetchingData } = useFetch(ENDPOINT_URL)
  const { action: post } = usePost()

  const initialValues = {
    headline: data?.landing[0]?.headline || '',
    call_to_action: data?.landing[0]?.call_to_action || '',
    image: data?.landing[0]?.image || ''
  }

  const validationSchema = yup.object().shape({
    headline: yup
      .string()
      .max(80, 'Título da seção não pode ultrapassar 80 caracteres')
      .required('Esse campo é obrigatório')
      .trim(),
    call_to_action: yup
      .string()
      .max(15, 'Texto do botão não pode ultrapassar 15 caracteres')
      .required('Esse campo é obrigatório')
      .trim(),
    image: yup.string().required('Esse campo é obrigatório')
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleSubmit
  } = useFormik({
    initialValues,
    isInitialValid: validationSchema.isValidSync(initialValues),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: formValues => {
      setPosting(true)
      const { headline, call_to_action, image } = formValues
      post(ENDPOINT_URL, {
        headline: headline,
        call_to_action: call_to_action,
        image: image,
        id: data?.landing[0]?.id
      }).then(() => {
        window.location.reload(false)
      })
    }
  })

  const handleImageUpload = (file: string) => {
    setFieldValue('image', file)
    setFieldTouched('image', true, false)
    validateForm({ ...values, image: file })
  }

  const canPost = (): boolean => {
    const { headline, call_to_action, image } = values
    if (headline !== initialValues.headline) return true
    if (call_to_action !== initialValues.call_to_action) return true
    if (image !== initialValues.image) return true
    return false
  }

  if (fetchingData) return <LoadingScreen />

  return (
    <PageContainer>
      <Sidebar />
      <SectionContainer>
        <Profile />
        <ContentContainer>
          <FormWrapper alignCenter>
            <form onSubmit={handleSubmit}>
              <FormRow>
                <FormColumn>
                  <Heading>Home</Heading>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Título/frase exibido na seção"
                    maxLength={80}
                    {...getFieldProps('headline')}
                    error={
                      errors.headline && touched.headline
                        ? errors.headline.toString()
                        : ''
                    }
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <TextField
                    label="Texto do botão"
                    maxLength={15}
                    {...getFieldProps('call_to_action')}
                    error={
                      errors.call_to_action && touched.call_to_action
                        ? errors.call_to_action
                        : ''
                    }
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <FormGroup
                    title="Imagem de fundo"
                    error={errors.image && touched.image ? errors.image : ''}
                  >
                    <Uploader
                      onUpload={handleImageUpload}
                      accept={['image/png', 'image/jpg', 'image/jpeg']}
                      {...getFieldProps('image')}
                    />
                  </FormGroup>
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn>
                  <Button
                    primary
                    loading={posting}
                    disabled={!isValid || !canPost()}
                  >
                    Salvar alterações
                  </Button>
                </FormColumn>
              </FormRow>
            </form>
          </FormWrapper>
        </ContentContainer>
      </SectionContainer>
    </PageContainer>
  )
}
