import { useState, useEffect } from 'react'
import { api } from '../api'

interface IState {
  data: any
  loading: boolean
  error: string
  action?: (url: string, data: any, insert?: boolean) => void
}

export const useFetch = (url: string): IState => {
  const [state, setState] = useState<IState>({
    data: null,
    loading: false,
    error: ''
  })

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setState(current => ({ ...current, loading: true, error: '' }))
      try {
        const response = await api.get(url)
        const { data } = response
        setState(current => ({ ...current, data, loading: false }))
      } catch (err) {
        setState(current => ({
          ...current,
          error: err.message,
          loading: false
        }))
      }
    }
    fetchData()
  }, [])

  return state
}

export const usePost = () => {
  const action = (url: string, data: any, insert?: boolean): Promise<any> => {
    const content = JSON.stringify({ ...data, operation: insert ? 1 : 0 })
    return api.post(url, content, {
      headers: { 'content-type': 'application/json' }
    })
  }

  return { action }
}

export const useDelete = () => {
  const action = (url: string, data: any): Promise<any> => {
    const content = JSON.stringify(data)
    return api.delete(url, {
      headers: { 'content-type': 'application/json' },
      data: content
    })
  }

  return { action }
}
