import styled from 'styled-components'

export const StyledButton = styled.button`
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  background: ${({ theme }) => theme.button.secondary.background};
  color: ${({ theme }) => theme.button.secondary.label};

  transition: all 0.2s ease-in-out;

  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.button.secondary.hover};
  }

  &:active {
    background: ${({ theme }) => theme.button.secondary.active};
  }

  svg {
    fill: ${({ theme }) => theme.button.secondary.label};
  }
`
