import React from 'react'
import { AnimationBar, Label, Page } from './styles'

export const LoadingScreen: React.FC = () => {
  return (
    <Page>
      <Label>Carregando</Label>
      <AnimationBar />
    </Page>
  )
}
