import styled from 'styled-components'

interface Props {
  image?: string
}

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  justify-content: stretch;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.box};
  z-index: 1;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    background: ${({ theme }) => theme.box};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0.25rem;

    z-index: -100000;

    transition: all 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      top: -0.25rem;
      left: -0.25rem;
      bottom: -0.25rem;
      right: -0.25rem;
    }
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 15.625rem;
  background-color: rgba(0, 0, 0, 0.65);
  background-image: url(${({ image }: Props) => image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem 0.25rem 0 0;
`

export const TextContainer = styled.div`
  padding: 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
  height: 5rem;
  background-color: ${({ theme }) => theme.sidebar.background};
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.fira};
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.general.text};
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
`

export const SubHeading = styled.h3`
  font-family: ${({ theme }) => theme.font.nunito};
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.general.text};
  white-space: nowrap;
  text-overflow: ellipsis;
`
