import styled, { css } from 'styled-components'

interface HeadingProps {
  alignLeft?: boolean
  alignCenter?: boolean
  alignRight?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  ${({ alignCenter, alignRight }: HeadingProps) => {
    if (alignCenter) {
      return css`
        text-align: center;
      `
    }
    if (alignRight) {
      return css`
        text-align: right;
      `
    }
    return css`
      text-align: left;
    `
  }}
`
export const Title = styled.h2`
  margin: 0;
  font-family: ${({ theme }) => theme.font.fira};
  font-size: 2rem;
  font-weight: 700;
  font-style: italic;
  color: ${({ theme }) => theme.general.text};
  text-transform: uppercase;
`
