import styled from 'styled-components'

interface GridProps {
  columns?: string
  rows?: string
}

export const GridView = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }: GridProps) => columns};
  grid-template-rows: ${({ rows }: GridProps) => rows};
  column-gap: 1rem;
  row-gap: 1rem;
`
