import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Wrapper = styled.aside`
  position: relative;
  width: 100%;
  max-width: 15rem;
  height: 100vh;
  background: ${({ theme }) => theme.sidebar.background};
  display: grid;
  grid-template-rows: 5rem 1fr 5rem;

  &:before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme }) => theme.sidebar.border};
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 5rem;
  padding-left: 2rem;

  img {
    width: 170px;
  }
  /* background-color: rgba(0, 0, 0, 0.2); */
`

export const MenuWrapper = styled.div`
  padding: 2rem 1rem;
`

export const MenuItem = styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 2.125rem;
  margin-bottom: 0.5rem;
  padding: 0 1.5rem;
  color: ${({ theme }) => theme.sidebar.color};
  border-radius: 0.25rem;

  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.sidebar.activebackground};
  }

  &.active {
    color: ${({ theme }) => theme.sidebar.activeColor};

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0.375rem;
      width: 0.125rem;
      height: 1rem;
      border-radius: 0.0625rem;
      background: ${({ theme }) => theme.button.primary.background};
    }
  }
`
