import React from 'react'
import { GridView } from './styles'

interface GridProps {
  columns?: string
  rows?: string
  children?: JSX.Element | JSX.Element[]
}

export const Grid: React.FC<GridProps> = ({
  columns = '1fr',
  rows = '1fr',
  children
}) => {
  return (
    <GridView columns={columns} rows={rows}>
      {children}
    </GridView>
  )
}
