import React from 'react'
import { Wrapper, Title } from './styles'

interface HeadingProps {
  children: string
  alignCenter?: boolean
  alignRight?: boolean
}
export const Heading: React.FC<HeadingProps> = ({
  children,
  alignCenter,
  alignRight
}) => {
  return (
    <Wrapper alignCenter={alignCenter} alignRight={alignRight}>
      <Title>{children}</Title>
    </Wrapper>
  )
}
