import React, { useContext } from 'react'
import { LogoContainer, Wrapper, MenuWrapper, MenuItem } from './styles'

import logoLight from './assets/main-logo--light.svg'
import logoDark from './assets/main-logo--dark.svg'
import { ThemeChanger } from '../../App'

export const Sidebar: React.FC = () => {
  const { theme } = useContext(ThemeChanger)
  return (
    <Wrapper>
      <LogoContainer>
        {theme.title === 'light' ? (
          <img src={logoDark} alt="" />
        ) : (
          <img src={logoLight} alt="" />
        )}
      </LogoContainer>
      <MenuWrapper>
        <MenuItem activeClassName="active" exact to="/inicio">
          Home
        </MenuItem>
        <MenuItem activeClassName="active" exact to="/marcas">
          Marcas
        </MenuItem>
        <MenuItem activeClassName="active" exact to="/servicos">
          Serviços
        </MenuItem>
        <MenuItem activeClassName="active" exact to="/clientes">
          Clientes
        </MenuItem>
        <MenuItem activeClassName="active" exact to="/empresa">
          Empresa
        </MenuItem>
        <MenuItem activeClassName="active" exact to="/contato">
          Contato
        </MenuItem>
      </MenuWrapper>
    </Wrapper>
  )
}
