import React, { createContext } from 'react'

import { Router } from 'react-router-dom'
import { Routes } from './routes'
import history from './history'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from './styles/global'
import { light, dark } from './styles/theme'

import usePersistedState from './hooks/usePersistedState'
import { AuthProvider } from './context/auth'
interface ContextInterface {
  theme: any
  handleTheme: () => void
}

export const ThemeChanger = createContext({} as ContextInterface)

const App: React.FC = () => {
  const [theme, setTheme] = usePersistedState(light)

  function handleTheme() {
    setTheme(theme.title === 'light' ? dark : light)
  }

  return (
    <ThemeProvider theme={theme}>
      <ThemeChanger.Provider value={{ theme, handleTheme }}>
        <AuthProvider>
          <Router history={history}>
            <Routes />
          </Router>
          <GlobalStyle />
        </AuthProvider>
      </ThemeChanger.Provider>
    </ThemeProvider>
  )
}

export default App
