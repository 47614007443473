/* eslint-disable camelcase */
import { useEffect, useState } from 'react'

import { api } from '../api'
import history from '../history'

export interface AuthProviderProps {
  authenticated: boolean
  loading: boolean
  handleLogin: ({
    user,
    password
  }: {
    user: string
    password: string
  }) => Promise<string>
  handleLogout: () => void
}

export interface LoginReturnProps {
  token?: string
  user_firstname?: string
  user_lastname?: string
  dark_mode?: string
  error: number
  message?: string
}

export interface SuccessProps {
  token: string
  user_firstname: string
  user_lastname: string
  dark_mode: string
}

export const useAuth = (): AuthProviderProps => {
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const data = localStorage.getItem('motec_dashboard')

    if (data) {
      const { token } = JSON.parse(data)
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthenticated(true)
    }

    setLoading(false)
  }, [])

  const handleLogin = async ({
    user,
    password
  }: {
    user: string
    password: string
  }): Promise<string> => {
    try {
      const content = JSON.stringify({ user, password })
      const { data } = await api.post('/authenticate.php', content, {
        headers: { 'content-type': 'application/json' }
      })

      const { token, user_firstname, user_lastname, dark_mode, error } = data

      if (error) {
        return data.message
      }

      const local = {
        token,
        firstname: user_firstname,
        lastname: user_lastname,
        darkMode: dark_mode
      }
      localStorage.setItem('motec_dashboard', JSON.stringify(local))
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthenticated(true)
      history.push('/inicio')
      return 'ok'
    } catch (err) {
      return err.message
    }
  }

  const handleLogout = (): void => {
    setAuthenticated(false)
    localStorage.removeItem('motec_dashboard')
    api.defaults.headers.Authorization = undefined
    history.push('/login')
  }

  return { authenticated, loading, handleLogin, handleLogout }
}
