import React from 'react'
import { Button, ButtonWrapper, Modal } from '../../ui'

interface ModalProps {
  title: string
  text?: string
  onConfirm?: () => void
  onCancel: () => void
}

export const ConfirmationDialog: React.FC<ModalProps> = ({
  title,
  text,
  onConfirm,
  onCancel
}) => {
  return (
    <Modal title={title}>
      <>
        {!!text && <p>{text}</p>}
        <ButtonWrapper>
          <Button type="button" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="button" primary onClick={onConfirm}>
            Confirmar
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  )
}
