/* eslint-disable no-undef */
import { FormikErrors } from 'formik'
import React from 'react'
import { Wrapper, Label, ErrorLabel } from './styles'

interface FormGroupInterface {
  title: string
  children: JSX.Element
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

export const FormGroup: React.FC<FormGroupInterface> = ({
  title,
  children,
  error
}) => {
  return (
    <Wrapper>
      <Label>{title}</Label>
      {children}
      {!!error && <ErrorLabel>{error}</ErrorLabel>}
    </Wrapper>
  )
}
