import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.general.overlay};
  z-index: 9000;
`

export const Box = styled.div`
  width: 100%;
  height: auto;
  max-width: 40rem;
  max-height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.modal.background};
  box-shadow: 0px 1px 6px ${({ theme }) => theme.modal.shadow};
  overflow-y: auto;
  z-index: 9000;
`

export const Header = styled.header`
  position: relative;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h3`
  margin: 0;
  font-family: ${({ theme }) => theme.font.fira};
  font-size: 1.5rem;
  font-weight: 700;
  font-style: italic;
  color: ${({ theme }) => theme.general.text};
  text-transform: uppercase;
`

export const Body = styled.div`
  position: relative;
  padding: 1rem;
  width: 100%;
`
