import React from 'react'
import { CardWrapper, Image, ImageContainer } from './styles'

interface CardProps {
  image?: string
  contain?: boolean
  onClick?: () => void
}

export const Card: React.FC<CardProps> = ({ contain, image, onClick }) => {
  return (
    <CardWrapper onClick={onClick}>
      <ImageContainer>
        <Image contain={contain} src={image} />
      </ImageContainer>
    </CardWrapper>
  )
}
