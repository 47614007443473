import React, { createContext } from 'react'
import { AuthProviderProps, useAuth } from '../hooks/useAuth'

interface AuthContextProps {
  children: JSX.Element | JSX.Element[]
}

const Context = createContext({} as AuthProviderProps)

const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const { authenticated, loading, handleLogin, handleLogout } = useAuth()

  return (
    <Context.Provider
      value={{
        loading,
        authenticated,
        handleLogin,
        handleLogout
      }}
    >
      {children}
    </Context.Provider>
  )
}
export { Context, AuthProvider }
