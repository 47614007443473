/* eslint-disable camelcase */
import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as yup from 'yup'
import { usePost, useDelete } from '../../hooks/useXHR'
import {
  Button,
  ButtonWrapper,
  FormColumn,
  FormGroup,
  FormRow,
  Modal
} from '../../ui'
import { ConfirmationDialog, TextField, Uploader } from '..'

const ENDPOINT_CLIENTS = '/clients.php'

interface ClientProps {
  id_clients: string
  name: string
  logo: string
  url: string
  activate: string
  update: string
}

interface ModalProps {
  value: ClientProps
  onClose: () => void
  onChange: () => void
}

export const ClientModal: React.FC<ModalProps> = ({
  value,
  onClose,
  onChange
}) => {
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const { action: post } = usePost()
  const { action: removeBrand } = useDelete()

  const validationSchema = yup.object().shape({
    name: yup.string().required('Esse campo é obrigatório').trim(),
    logo: yup.string().required('Esse campo é obrigatório'),
    url: yup.string().required('Esse campo é obrigatório')
  })

  const {
    errors,
    touched,
    values,
    getFieldProps,
    isValid,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleSubmit
  } = useFormik({
    initialValues: value,
    validationSchema,
    enableReinitialize: true,
    isInitialValid: validationSchema.isValidSync(value),
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: formValues => {
      const { name, logo, url } = formValues
      post(ENDPOINT_CLIENTS, {
        id_clients: value.id_clients,
        name,
        logo,
        url
      })
        .then(() => {
          onChange()
        })
        .catch(() => {
          console.log('Error')
        })
    }
  })

  const handleDelete = () => {
    removeBrand(ENDPOINT_CLIENTS, {
      id_clients: value.id_clients
    })
      .then(() => {
        onChange()
      })
      .catch(err => console.debug(err))
  }

  const handleImageUpload = (file: string) => {
    setFieldValue('logo', file)
    setFieldTouched('logo', true, false)
    validateForm({ ...values, logo: file })
  }

  const canPost = (): boolean => {
    const { name, logo, url } = values
    if (name !== value.name) return true
    if (logo !== value.logo) return true
    if (url !== value.url) return true
    return false
  }

  return (
    <>
      <Modal title="Editar cliente" onClose={onClose} showCloseButton>
        <form onSubmit={handleSubmit}>
          <FormRow>
            <FormColumn>
              <TextField
                label="Nome do cliente"
                {...getFieldProps('name')}
                error={errors.name && touched.name ? errors.name : ''}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <TextField
                label="Link para o site do cliente"
                {...getFieldProps('url')}
                error={errors.url && touched.url ? errors.url : ''}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormGroup
                title="Logotipo do cliente"
                error={errors.logo && touched.logo ? errors.logo : ''}
              >
                <Uploader
                  onUpload={handleImageUpload}
                  accept={['image/png', 'image/jpg', 'image/jpeg']}
                  {...getFieldProps('logo')}
                />
              </FormGroup>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <ButtonWrapper>
                <Button
                  danger
                  type="button"
                  onClick={() => setDeleteDialog(true)}
                >
                  Excluir
                </Button>
                <Button type="submit" primary disabled={!isValid || !canPost()}>
                  Salvar alterações
                </Button>
              </ButtonWrapper>
            </FormColumn>
          </FormRow>
        </form>
      </Modal>
      {deleteDialog && (
        <ConfirmationDialog
          title="Excluir cliente?"
          onCancel={() => setDeleteDialog(false)}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
