import React, { useState, useEffect, memo } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Wrapper } from './styles'
import boldIcon from './assets/format_bold.svg'
import italicIcon from './assets/format_italic.svg'
import underlineIcon from './assets/format_underlined.svg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
// import htmlToDraft from 'html-to-draftjs'

interface TextAreaProps {
  value: string
  onChange: (richText: string) => void
}

const TextArea: React.FC<TextAreaProps> = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks)
    )
  )

  useEffect(() => {
    const bla = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onChange(bla)
  }, [editorState])

  // useEffect(() => {
  //   setEditorState(
  //     EditorState.createWithContent(
  //       ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks)
  //     )
  //   )
  // }, [value])

  const onEditorStateChange = (state: EditorState) => {
    setEditorState(state)
    const bla = draftToHtml(convertToRaw(state.getCurrentContent()))
    onChange(bla)
  }

  return (
    <Wrapper>
      <Editor
        editorState={editorState}
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            inDropdown: false,
            bold: { icon: boldIcon },
            italic: { icon: italicIcon },
            underline: { underlineIcon }
          }
        }}
        onEditorStateChange={onEditorStateChange}
      />
    </Wrapper>
  )
}

export default memo(TextArea)
